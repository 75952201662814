import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CHeader,CHeaderBrand,CHeaderNav,CHeaderToggler,CHeaderDivider,
	CFooter,
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormTextarea,
	CInputGroup,
	CInputGroupText,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CLink,
	CTooltip,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import {
	MyCardodontogram,
	MyButton,
} from '../../components/index'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc } from '../../helpers/functions'

const pjson 		= require('../../../package.json')
const Odontogramform= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif 
	} = props;

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	const { tabel_id } 			= useParams(); 
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uUrlbefore 				= useSelector(state => state.gUrlbefore);
	const uPasienID 				= useSelector(state => state.gPasienIDedit)||0;
	const [uEfekview,setEfekview] 	= React.useState(false);

	const uRekammedisID				= parseInt(tabel_id)||0;
	const uOdontograminputArr		= useSelector(state => state.gOdontograminputArr) || [];
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN");
	const [uHtml500msg,setHtml500msg]	= React.useState(); 

	const [uOdontogramdefaultArr,setOdontogramdefaultArr]		= React.useState([]);
	const [uDatamainObj,setDatamainObj]		= React.useState({})
	const [uISReloadodontogram,setReloadodontogram]		= React.useState(false);
	const [uISFormupdate,setFormupdate]		= React.useState(false);
	const [uISPageprocessing,setPageprocessing]		= React.useState(false);

	//--DLG_FORM--/
	//--END DLG_FORM--/

	//---HANDEL--/
	const hdlKDownDocument = (_EV) => {
		if(_EV.keyCode === 120) {
			const vElsimpan = document.getElementById("btnSimpanmain");
			vElsimpan && (vElsimpan.click())
			return;
		}
	}
	const hdlKlikBack = async() => { 
		if(!uISFormupdate) 
			{ uNavigate(-1); return; }

		if(await Konfirm("<b>Data Odontogram Sudah Ada Yang Berubah dan Belum DISIMPAN</b>.."+
			"<br/>Jika Anda Akan KEMBALI, Perubahan Data Akan DIBATALKAN. Silahkan KLIK SIMPAN, Untuk Menyimpan Perubahan Data ! "+
			"<br/><br/>Apakah Anda Yakin ?"+
			"")) uNavigate(-1);
	}
	const hdlKlikReset = async() => {
		//console.log("(Odontogramform - hdlKlikReset) uOdontogramdefaultArr => "+JSON.stringify(uOdontogramdefaultArr))
		if(uHtml500msg || !uISFormupdate) {
			uDispatch({type: "set", gOdontograminputArr: undefined});
			apiLoaddata(); setReloadodontogram(true); return;
		}

		if(await Konfirm("<b>Data Odontogram Sudah Ada Yang Berubah dan Belum DISIMPAN</b>.."+
			"<br/>Jika Anda Akan MERESET, Perubahan Data Akan DIBATALKAN. "+
			"<br/><br/>Apakah Anda Yakin ?"+
			"")) {
			uDispatch({type: "set", gOdontograminputArr: undefined});
			//uDispatch({type: "set", effectApp: !uEfekapp});
			setReloadodontogram(true);
		}
	}
	const hdlKlikSimpanmain = () => {
		//alert(JSON.stringify(uOdontograminputArr)); return;
		if(uOdontograminputArr.length <= 0) return;
		apiSimpanmain();
	}
	//---END HANDEL--/

	//---INIT_PROCESS--/
	//---END INIT_PROCESS--/

	//---CONTENT--/
	const contentHeadersub = () => {
		if(uISPageprocessing) return (
			<div className="mx-auto text-primary fst-italic classblinking">Loading..</div>
		)//->

		if(uHtml500msg) return "Page 500";

		return (
		<>
		<div className="fw-bolder text-info">{uDatamainObj.pasien_caption || "Undf Pasien"}</div>

		<div className="classfontsmaller d-none d-md-block">
			<span>{UFunc.TglAngka(uDatamainObj.tgl_periksa)}</span> 
			<span className="mx-1">&middot;</span> 
			<span className="text-primary">{uDatamainObj.nama_dokter||"Drg.Undf"}</span> 
		</div>

		<div className="w-100 d-md-none my-2 classborderbottom"/>

		<div className="w-100 d-flex d-md-none justify-content-between my-1 classfontsmaller">
			<span>Tgl Periksa</span> 
			<span>{UFunc.TglAngka(uDatamainObj.tgl_periksa)}</span> 
		</div>

		<div className="w-100 d-flex d-md-none justify-content-between my-1 classfontsmaller">
			<span>Dokter</span> 
			<span className="text-primary">{uDatamainObj.nama_dokter||"Drg.Undf"}</span> 
		</div>
		</>
		)//>
	}
	//---END CONTENT--/

	//---REST_API--/
	const apiLoaddata = () => {
		setHtml500msg();

		setDatamainObj({})
		setPageprocessing(true);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		console.log("(Odontogramform - apiLoaddata) LEWAT ");
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);

			setPageprocessing(false);
			setLoading(false);
		},3000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_rekammedisid : uRekammedisID,
			send_cabangid: uTokenObj.user_cabangid,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_rekammedis/ld_rmdata";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			setPageprocessing(false);
			setLoading(false);
			if(output_string.tampil) {
				setDatamainObj(JSON.parse(output_string.datarekammedis||"{}"))
				const vTmpLoadArr	= JSON.parse(output_string.dataodontogram||"[]");

				setOdontogramdefaultArr(
					vTmpLoadArr.map(vItems=>{
						return {
							id: vItems.id,
							nomor_gigi	: vItems.nomor_gigi,
							kondisi_id	: vItems.kondisi_id,
							kondisi_caption	: vItems.kondisi_caption,
							kondisi_kode	: vItems.kondisi_kode,
							kondisi_simbol	: vItems.kondisi_simbol,
							surface	: vItems.kondisi_surface,
							catatan: vItems.catatan,
						}
					})
				);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(Odontogramform - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired(); return;
			}

			if(vTimeDiff > 500) setSessionaktif(true);
			else {
				let vTimeout = setTimeout(()=>{ clearTimeout(vTimeout); setSessionaktif(true);},500)
			}
		})
		.catch((error) =>{
			setPageprocessing(false);
			setLoading(false);
			console.log("(Odontogramform - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiSimpanmain = () => {
		const vElsimpan	= document.getElementById("btnSimpanmain")
		vElsimpan && (vElsimpan.disabled = true);

		setLoading(true);

		/*let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			vElsimpan && (vElsimpan.disabled = false);
			setLoading(false);
		},2500);return;//-*/

		const vDATAS	= JSON.stringify({
			send_pasienid: uPasienID,
			send_rekammedisid: uRekammedisID,
			send_cabangid: uTokenObj.user_cabangid,
			send_dataodontogram : JSON.stringify(uOdontograminputArr),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_periksa/pr_simpanodontogram";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			setLoading(false);
			if(output_string.sukses) {
				uDispatch({type: "set", gOdontograminputArr: undefined});
				showToast((uBahasaObj.pesan_sukses||"Berhasil"),"SUKSES");
				uNavigate(-1); return;
			} else if(output_string.periksa_invalid) {
				uDispatch({type: "set", gOdontograminputArr: undefined});
				showToast(output_string.periksa_invalid);
				uNavigate(-1); return;
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Odontogramform - apiSimpanmain) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired(); return;
			}

			vElsimpan && (vElsimpan.disabled = false);
			if(vTimeDiff > 500) setSessionaktif(true);
			else {
				let vTimeout = setTimeout(()=>{ clearTimeout(vTimeout); setSessionaktif(true);},500)
			}
		})
		.catch((error) =>{
			vElsimpan && (vElsimpan.disabled = false);
			setLoading(false);
			console.log("(Odontogramform - apiSimpanmain) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR")
		});
	}
	//---END REST_API--/

	//---EFFECT--/
	React.useEffect(()=>{
		document.addEventListener("keydown",hdlKDownDocument);

		return ()=>{
			document.removeEventListener("keydown",hdlKDownDocument);
		}
	})
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
    	
    	apiLoaddata();

    	return () => {
    		setFormupdate(false);
    		setOdontogramdefaultArr([]);
			//--TESTING_DISABLED--/
			uDispatch({type: "set", gOdontograminputArr: undefined});
			uDispatch({type: "set", gPasienIDedit: undefined});
			//--END TESTING_DISABLED--*/
    		setDatamainObj({});
    	}
	},[])
	React.useEffect(()=>{
		if(!uISReloadodontogram) return;

		setReloadodontogram(false);
	},[uISReloadodontogram])
	React.useEffect(()=>{
		if(uOdontograminputArr.length !== uOdontogramdefaultArr.length) {
			setFormupdate(true); return;
		}

		//console.log("(Odontogramform []) uOdontogramdefaultArr => "+JSON.stringify(uOdontogramdefaultArr))
		let vISAdaygbeda = false;
		uOdontogramdefaultArr.map(vItems => {
			const vDatafilterArr	= uOdontograminputArr.filter(vIteminput => {
				return (
					(parseInt(vIteminput.id)||0) === (parseInt(vItems.id)||0)
				)
			});

			if(parseInt(vItems.nomor_gigi) !== parseInt((vDatafilterArr[0]||{}).nomor_gigi)
				|| parseInt(vItems.kondisi_id) !== parseInt((vDatafilterArr[0]||{}).kondisi_id)
				|| (vItems.kondisi_caption||"").trim().toUpperCase() !== 
					((vDatafilterArr[0]||{}).kondisi_caption||"").trim().toUpperCase()
				|| (vItems.kondisi_simbol||"").trim().toUpperCase() !== 
					((vDatafilterArr[0]||{}).kondisi_simbol||"").trim().toUpperCase()
				|| (vItems.surface||"").trim().toUpperCase() !== 
					((vDatafilterArr[0]||{}).surface||"").trim().toUpperCase()
				|| (vItems.catatan||"").trim().toUpperCase() !== 
					((vDatafilterArr[0]||{}).catatan||"").trim().toUpperCase()
				|| (vItems.status_hapus||"TIDAK").trim().toUpperCase() !== 
					((vDatafilterArr[0]||{}).status_hapus||"TIDAK").trim().toUpperCase()
			) vISAdaygbeda =  true;
		});

		//console.log("(Odontogramform []) vISAdaygbeda => "+(vISAdaygbeda))
		setFormupdate(vISAdaygbeda);
	},[uOdontogramdefaultArr.length,uOdontograminputArr.length,uEfekapp])
	//---END EFFECT--/

	//console.log("(Odontogramform) uOdontogramdefaultArr => "+JSON.stringify(uOdontogramdefaultArr))
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-3 p-0">
		<CContainer fluid className="classheadertop" style={{minHeight:64}}>
			<CHeaderToggler className="px-0" onClick={() => hdlKlikBack() }>
			<CIcon icon="cilArrowCircleLeft" className="align-top me-2" height={30}/>
			</CHeaderToggler>
			
			<CHeaderBrand className="me-auto text-normal">
				<b>Edit Odontogram</b>
			</CHeaderBrand>

			<CHeaderNav>
				<CLink onClick={hdlKlikReset} className="classcursorpointer classikon classikonreset"/>
			</CHeaderNav>
		</CContainer>

		<CContainer fluid className="bg-white p-2 px-3 px-lg-5" style={{minHeight:52}}>
			{contentHeadersub()}
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-2 px-lg-5 mb-3">
		<CContainer xxl className="px-0">
			{(uHtml500msg) ? (
			<CCard className="classbgcard mb-2">
				<CCardHeader>Error Page 500</CCardHeader>
				<CCardBody>
					<div className="m-lg-5">{UFunc.renderHTML(uHtml500msg)}</div>
				</CCardBody>
			</CCard>
			) : (
				<MyCardodontogram pasienID={uPasienID} rekammedisID={uRekammedisID}
					triggerReload={uISReloadodontogram}
					 {...props}/>
			)}
		</CContainer>
		</div>

		<CFooter position="sticky" className="px-3 text-black-50 justify-content-center" 
			style={{backgroundColor:"#AEAEAE99",borderTop:"1px dashed #f0f0f0"}}>
		<MyButton
			onClick={()=>hdlKlikSimpanmain()}
			disabled={uISPageprocessing || uHtml500msg || !uISFormupdate}
			id="btnSimpanmain">{uBahasaObj.word_simpan||"Simpan"} [F9]</MyButton>
		</CFooter>
		</div>
		</>
	)//->
}	

export default Odontogramform