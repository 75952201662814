import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CHeaderNav,
	CDropdown,
	CDropdownToggle,
	CDropdownMenu,
	CDropdownHeader,
	CDropdownItem,
	CDropdownDivider,
	CAvatar,
	CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { Konfirm } from '../helpers/onConfirm';
import { UFunc } from '../helpers/functions'
import PropTypes from "prop-types";

const pjson 			= require('../../package.json');

const MyHeaderProfile = (props) => {
	const {
		setToken,setLoading,showToast,clearStorage,isWithLogout,...other
	} = props; 

	const uNavigate 	= useNavigate();

	const uTokenobj		= JSON.parse(localStorage.getItem("token")||"{}");
	const uDatacabangArr= JSON.parse(localStorage.getItem("cabanglist")||"[]");
	const uBahasaob  	= useSelector(state => state.listBahasa);
	const uBranchcode	= useSelector(state => state.branchCode); 
	const uCabangattrObj= useSelector(state => state.gCabangatribut) || {}; 

	const uAvatardefault= () => {  
		switch((uTokenobj.userhak||"ENTRI").toUpperCase()) {
			case "FREEROLE":
				return pjson.homepage+"api/images/img_freerole.png";
				break
			case "ADMIN":
				return pjson.homepage+"api/images/img_admin.png";
				break
			case "DOKTER":
				return pjson.homepage+"api/images/img_dokter.png";
				break
			default:
				return pjson.homepage+"api/images/img_entri.png";
		}
	}

	const hdlKlikLogout	= async() => {
		if(await Konfirm(uBahasaob.confirm_logout||"Apakah Yakin Akan Logout ?")) 
			ApiProseslogout();
	}

	const ApiProseslogout = () => {
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout	= setTimeout(() => {
			setLoading(false);
			clearStorage();
			showToast(uBahasaob.pesan_hasloggedout||"Anda Telah Logout..");
			setToken("");
		},2000); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenobj.userinit,
			send_device	: pjson.name
		});
		const vURLs	= pjson.homepage+"api/api_login/pr_logout";
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST", mode: "cors",
			cache: "default", body: vDATAS,
		}).then((response)=>{ if (response.status === 200) return response.json(); })
		.then((output_string) => {
			setLoading(false);
			if(output_string.sukses) {
		    	clearStorage();
				setToken("");
				showToast(uBahasaob.pesan_hasloggedout||"Anda Telah Logout..");
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(MyHeaderProfile - ApiProseslogout) output_string.errors: "+output_string.errors);
				var vMsg;
				if(pjson.mydefault.environment==="development")
					vMsg = output_string.errors;
				else vMsg = pjson.mydefault.msgFetchError;

				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setToken("");
			}
		}) .catch((error) =>{
			setLoading(false);
			console.log("(MyHeaderProfile - ApiProseslogout) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(""),"ERROR");
		});
	}

	React.useEffect(()=>{},[])

	//console.log("(MyHeaderProfile) isWithLogout = "+isWithLogout)
	return (
		<div className="d-flex align-items-center">
		<div className="me-2 d-none d-md-block text-center">
				<div className="classfontoswald classfontcolormain text-capitalize">
				&middot;{(()=>{
					if(uDatacabangArr.length <= 1) return (<>{uCabangattrObj.nama_klinik}</>);

					return (<>{(uCabangattrObj.nama||"").toLowerCase()}</>);
				})()}&middot;
				</div>
		</div>

		<CDropdown>
			<CDropdownToggle className="p-0" color="transparent" caret={false}>
				<CAvatar src={uAvatardefault()} size="md"/>
			</CDropdownToggle>

			<CDropdownMenu className="pt-0">
        	<CDropdownHeader className="bg-light fw-semibold py-2 px-4 text-center">
	          	<div className="mb-2"><CIcon icon="cilUser" height={30} /></div>
				<strong>{(UFunc.Usercaptionfilter(uTokenobj.usercaption)).toUpperCase()}</strong>
				<div className="classfontsmaller my-1">{"Hak Akses: "+(uBahasaob["hakuser_"+(uTokenobj.userhak||"").toLowerCase()]||(uTokenobj.userhak||"")).toUpperCase()}</div>
				<div className="classfontsmaller classfontoswald fw-normal classfontcolormain d-md-none">
					&middot;{uCabangattrObj.nama || uCabangattrObj.nama_klinik}&middot;
				</div>
        	</CDropdownHeader>

			{(isWithLogout) && (
			<CDropdownItem className="py-2 px-4 classcursorpointer" onClick={()=>hdlKlikLogout()}>
			<CIcon icon="cilAccountLogout" className="me-2 classverticalmiddle" height={20}/>
			{uBahasaob.caption_logout||"Logout Aplikasi"}
			</CDropdownItem>
			)}
			</CDropdownMenu>
		</CDropdown>
		</div>
	)
}
MyHeaderProfile.propTypes = {
	isWithLogout: PropTypes.bool,
}
MyHeaderProfile.defaultProps = { 
	isWithLogout: true 
}

export default MyHeaderProfile
	