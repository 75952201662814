import React from 'react';
import { useSelector } from 'react-redux';
import {
	CButton,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
	CCloseButton,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { MyButton } from './index';
import { UFunc } from "../helpers/functions";
import PropTypes from 'prop-types';

const MyDialogform = props => {	
	const {show,toggle,renderContent,dialogHeader,onSimpan,options,...rest} = props;
	const uBahasaObj  = useSelector(state => state.listBahasa);

	return (
		<CModal
			size={options.hasOwnProperty("size")?options.size:undefined}
			alignment={(options.centered)===true?"center":"top"}
			visible={show}
			scrollable
			{...rest}>
		<CModalHeader 
			className="bg-light text-dark py-2"
			closeButton={false}>
			<CModalTitle>
				<div className="d-none d-md-block">{UFunc.renderHTML(dialogHeader)}</div>
				<small className="d-md-none">{UFunc.renderHTML(dialogHeader)}</small>
			</CModalTitle>
			<CCloseButton onClick={toggle}/>
		</CModalHeader>

		<CModalBody style={{}}>{renderContent}</CModalBody>

		<CModalFooter>
		<MyButton
			onClick={()=>onSimpan()}
			id="btnDialogSimpan">
			<CIcon icon="cilCheckAlt" 
			 	className="me-1 classverticalmiddle"
				height={20}/>
			{uBahasaObj.word_simpan||"Simpan"}
		</MyButton>
		<span className="me-1"/>
		<CButton color="light" onClick={toggle}>{uBahasaObj.word_batal||"Batal"}</CButton>
		</CModalFooter>
		</CModal>
	)//->
}
MyDialogform.defaultProps = {
	show: false,
	renderContent: "{UNDEFINED}..",
	dialogHeader: "Form Data",
	options: {centered:true,size:""},
};
MyDialogform.propTypes = {
	show: PropTypes.bool,            
	toggle: PropTypes.func,      
	renderContent: PropTypes.element, 
	dialogHeader: PropTypes.string,
	onSimpan: PropTypes.func,
	options: PropTypes.object,
}

export default MyDialogform;