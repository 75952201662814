import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {
	CButton,
	CForm,
	CFormInput,
	CFormSelect,
	CFormTextarea,
	CInputGroup,
	CInputGroupText,
	CSpinner,
	CTooltip,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CRow,
	CCol,
	CLink,
	CImage,
} from '@coreui/react';
import PropTypes from 'prop-types';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import { Konfirm } from '../helpers/onConfirm'
import { 
	UFunc,
} from "../helpers/functions";
import {
	MyDialogview,
	MyDialogform,
	MyDialoglookup,
} from '../components/index'

const pjson 			= require('../../package.json')
const MyCardodontogram 	= props => {
	const { 
		pasienID,
		rekammedisID,
		triggerReload,
		prosesExpired,
		showToast,
		setSessionaktif,
		setLoading,
		...rest 
	} = props;

	const uDispatch 					= useDispatch();

	const uBahasaObj					= useSelector(state => state.listBahasa);
	const uSystemsetting				= useSelector(state => state.gSystemsetting)
	const uEfekapp						= useSelector(state => state.effectApp);
	const uTokenObj						= JSON.parse(localStorage.getItem("token")||"{}");
	const [uEfekview,setEfekview] 		= React.useState(false);

	const uISTrigerreload			= triggerReload || false;
	const uOdontograminputArr		= useSelector(state => state.gOdontograminputArr) || [];
	const [uISCardloading,setCardloading] 	= React.useState(false);
	const [uHtml500msg,setHtml500msg] 		= React.useState();
	const [uDatagigiArr,setDatagigiArr] 	= React.useState([]);
	const [uDatakondisiArr,setDatakondisiArr]= React.useState([]);

	const [uDataodontogramArr,setDataodontogramArr] = React.useState([]);
	const [uDatahistoriArr,setDatahistoriArr] = React.useState([]);
	const [uDatainputArr,setDatainputArr] 	= React.useState(uOdontograminputArr);
	const [uISDatainputupdate,setDatainputupdate] 	= React.useState(false);

	//--DLG_FORM--/
	const [uISDlgformshow,setDlgformshow] 		= React.useState(false);
	const [uDlgformdataObj,setDlgformdataObj] 	= React.useState({});
	//--END DLG_FORM--/

	//--DLG_LOOKUP--/
	const [uISDlglookupshow,setDlglookupshow] 	= React.useState(false);
	const [uDlglookupObj,setDlglookupObj] 		= React.useState({});
	//--END DLG_LOOKUP--/

	//--DLG_LOOKUP--/
	const [uISDlgviewshow,setDlgviewshow] 		= React.useState(false);
	const [uDlgviewdataObj,setDlgviewdataObj] 	= React.useState({});
	//--END DLG_LOOKUP--/

	//--HANDEL--/
	const hdlKlikInfoodontogram = () => {
		setDlgviewdataObj({
			dlg_header: "Keterangan Simbol Odontogram"
		})
		setDlgviewshow(true);
	}
	const hdlKlikGigi = React.useCallback((_NOMORGIGI,_SURFACE) => {
   	 	//console.log("uDatagigiArr inside hdlKlikGigi => " + JSON.stringify(uDatagigiArr[0])); // Should correctly log the uDataArr
		if(uDatagigiArr.length <= 0) return;

		_NOMORGIGI	= parseInt(_NOMORGIGI); if(_NOMORGIGI <= 0) return;
		_SURFACE	= (_SURFACE||"ALL").toUpperCase();

		const vArridx		= UFunc.getObjectIndeks(uDatagigiArr,_NOMORGIGI,"nomor_gigi");
		const vCaptionupper = (((uDatagigiArr[vArridx]||{}).nama_gigi||"")+" ("+_NOMORGIGI+")").toUpperCase();

		setDlgformdataObj({
			dlg_header: "Set Kondisi: "+vCaptionupper,
			data_obj: {...(uDatagigiArr[vArridx]||{}),surface:_SURFACE},
			input_surface_klik: _SURFACE,
			input_surface: _SURFACE,
			input_nogigi: (uDatagigiArr[vArridx]||{}).nomor_gigi,
		})

		setDlgformshow(true);
	},[uDatagigiArr.length]);
	const hdlKlikkondisicaption = () => {
		const vTmpArr				= uDatakondisiArr.map(vItems => {
			return {
				id: vItems.id,
				caption: vItems.kode+" - "+vItems.arti,
			}
		});
		uDlglookupObj.dlg_header	= "Lookup KONDISI GIGI";
		uDlglookupObj.data_arr		= vTmpArr;

		setDlglookupshow(true);
	}
	const hdlKlikDlglookuppilih = (_IDITEM) => {
		_IDITEM 	= parseInt(_IDITEM);if(_IDITEM <= 0) return;

		const vArridx		= UFunc.getObjectIndeks(uDatakondisiArr,_IDITEM);
		uDlgformdataObj.input_kondisicaption = uDatakondisiArr[vArridx].kode+
			" - "+uDatakondisiArr[vArridx].arti+
			"";
		uDlgformdataObj.input_kondisiid 	= parseInt(uDatakondisiArr[vArridx].id) || 0;
		uDlgformdataObj.input_kondisisurface= uDatakondisiArr[vArridx].surface;

		uDlgformdataObj.input_surface 		= uDatakondisiArr[vArridx].surface==="ALL"
			? uDatakondisiArr[vArridx].surface : uDlgformdataObj.input_surface_klik;
		uDlgformdataObj.input_kondisikode 	= uDatakondisiArr[vArridx].kode;
		uDlgformdataObj.input_kondisisimbol	= uDatakondisiArr[vArridx].jenis_simbol;
		setDlglookupshow(false);

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);

			if(document.getElementById("inpsurface"))
				document.getElementById("inpsurface").focus();
			else {
				document.getElementById("inpcatatan") &&
					document.getElementById("inpcatatan").focus();
			}
		},350)
	}
	const hdlKlikKondisiedit = (_IDXKEY) => {
		_IDXKEY	= _IDXKEY===undefined ? -1 : parseInt(_IDXKEY); if(_IDXKEY < 0) return;

		const vArridx		= UFunc.getObjectIndeks(uDatagigiArr,
			uDatainputArr[_IDXKEY].nomor_gigi,
			"nomor_gigi");
		const vCaptionupper = ((uDatainputArr[_IDXKEY].nama_gigi||"")+" ("+uDatainputArr[_IDXKEY].nomor_gigi+")").toUpperCase();

		uDlgformdataObj.dlg_header	= "Edit Kondisi: "+vCaptionupper;
		uDlgformdataObj.data_obj	= {...uDatagigiArr[vArridx],surface:uDatainputArr[_IDXKEY].surface}

		uDlgformdataObj.input_idxkey		= _IDXKEY;
		uDlgformdataObj.input_surface_klik	= uDatainputArr[_IDXKEY].surface;
		uDlgformdataObj.input_surface 		= uDatainputArr[_IDXKEY].surface;
		uDlgformdataObj.input_nogigi		= uDatainputArr[_IDXKEY].nomor_gigi;
		uDlgformdataObj.input_kondisiid		= uDatainputArr[_IDXKEY].kondisi_id;
		uDlgformdataObj.input_kondisicaption= uDatainputArr[_IDXKEY].kondisi_caption;
		uDlgformdataObj.input_catatan		= uDatainputArr[_IDXKEY].catatan;
		uDlgformdataObj.input_kondisikode 	= uDatainputArr[_IDXKEY].kondisi_kode;
		uDlgformdataObj.input_kondisisimbol	= uDatainputArr[_IDXKEY].kondisi_simbol;

		setDlgformshow(true);
	}
	const hdlKlikDlgformsimpan = () => {
		//--VALIDASI--/
		let vElfocus	= document.getElementById("inpkondisicaption");
		if((parseInt(uDlgformdataObj.input_kondisiid)||0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Kondisi Gigi Belum Dipilih !"); return;
		}

		vElfocus	= document.getElementById("inpsurface");
		if((uDlgformdataObj.input_kondisisurface||"") === "ALL")
			if((uDlgformdataObj.input_surface||"") !== "ALL") {
				vElfocus && vElfocus.focus();
				showToast("Permukaan Gigi Tidak Valid !"); return;
			}

		const vIdxedit	= uDlgformdataObj.input_idxkey===undefined ? -1 : parseInt(uDlgformdataObj.input_idxkey);
		
		//--CEK_NO_GIGI_SURFACE_IDKONDISI_SUDAHADA--/
		const vKondisiinputArr	= uDatainputArr.filter((vItems,vKeys)=>{
			return (vItems.nomor_gigi === uDlgformdataObj.input_nogigi 
				&& parseInt(vItems.kondisi_id) === parseInt(uDlgformdataObj.input_kondisiid)
				&& (vItems.surface||"").toUpperCase() === (uDlgformdataObj.input_surface||"").toUpperCase() 
				&& vKeys !== vIdxedit
				)
		});
		if(vKondisiinputArr.length > 0) {
			showToast("<b>Kondisi Gigi Sudah Diinputkan</b>.."+
				"<br/>Kondisi dengan Nomor Gigi dan Permukaan Tersebut Sudah Ada !"+
				""); return;
		}

		const vKondisihistoriArr	= uDatahistoriArr.filter(vItems=>{
			return (vItems.nomor_gigi === uDlgformdataObj.input_nogigi 
				&& parseInt(vItems.kondisi_id) === parseInt(uDlgformdataObj.input_kondisiid)
				&& (vItems.surface||"").toUpperCase() === (uDlgformdataObj.input_surface||"").toUpperCase() 
				)
		});
		if(vKondisihistoriArr.length > 0) {
			vElfocus && vElfocus.focus();
			showToast("<b>Kondisi Gigi Sudah PERNAH Dimasukkan</b>.."+
				"<br/>Kondisi dengan Nomor Gigi dan Permukaan Tersebut Sudah Ada !"+
				""); return;
		}
		//--END CEK_NO_GIGI_SURFACE_IDKONDISI_SUDAHADA--/
		//--END VALIDASI--/

		//--CARI_INDEX_NOGIGI--/>JIKA_ADA_TARUH_BAWAHNYA
		const vArridx = UFunc.getObjectIndeks(uDatainputArr,uDlgformdataObj.input_nogigi,"nomor_gigi");
		//--END CARI_INDEX_NOGIGI--/

		const vInputObj	= {
			nomor_gigi	: uDlgformdataObj.input_nogigi,
			kondisi_id	: uDlgformdataObj.input_kondisiid,
			kondisi_caption	: uDlgformdataObj.input_kondisicaption,
			kondisi_kode	: uDlgformdataObj.input_kondisikode,
			kondisi_simbol	: uDlgformdataObj.input_kondisisimbol,
			surface	: uDlgformdataObj.input_surface,
			catatan: uDlgformdataObj.input_catatan,
		}

		//console.log("vArridx => "+vArridx);
		if(vIdxedit < 0) {
			if(vArridx < 0)
				setDatainputArr([vInputObj,...uDatainputArr]);
			else {
				setDatainputArr([
					...uDatainputArr.slice(0, vArridx),
					vInputObj,
					...uDatainputArr.slice(vArridx)
				]);
			}
		} else {
			if(parseInt(uDatainputArr[vIdxedit].id||0) > 0)
				uDatainputArr[vIdxedit] = {
					id: (parseInt(uDatainputArr[vIdxedit].id)||0),
					...vInputObj
				}
			else uDatainputArr[vIdxedit] = vInputObj;
		}
		setDatainputupdate(true);

		setDlgformshow(false);
	}
	const hdlKlikKondisihapus = async(_IDXKEY) => {
		if(await Konfirm("Apakah Yakin Kondisi Akan Dihapus ?")) {
			if((parseInt(uDatainputArr[_IDXKEY].id)||0) > 0)
				uDatainputArr[_IDXKEY].status_hapus = "YA";
			else uDatainputArr.splice(_IDXKEY,1);

			setDatainputupdate(true);
		}
	}
	//--END HANDEL--/

	//--RENDER_CONTENT--/
	const ContentToothM = React.useMemo(()=>{ 
		return React.memo(({ number, posisi, sisi, jenis}) => {
			const vKondisihistoriArr= uDatahistoriArr.filter(vItems=> parseInt(vItems.nomor_gigi)===parseInt(number));
			const vKondisiinputArr	= uDatainputArr.filter(vItems=> 
				parseInt(vItems.nomor_gigi)===parseInt(number)	&&
				(vItems.status_hapus||"TIDAK")!=="YA"
				);

			let vSimbolteks = "";
			let vSimbolimageArr			= [];
			let vSimbolcolormesial 		= "";
			let vSimbolcolordistal 		= "";
			let vSimbolcolorlingual 	= "";
			let vSimbolcolorvestibular 	= "";
			let vSimbolcoloroccusal 	= "";
			let vSimbolcolorall = "";
			let vKondisiidx;

			if(vKondisihistoriArr.length > 0) {
				let vTekstmp	= "";
				vKondisihistoriArr.map(vItems => {
					//--TEKS--/
					if(vItems.kondisi_simbol==="TEKS") {
						if(vTekstmp	=== "") {
							vSimbolteks 	= vItems.kondisi_kode;
							vTekstmp		= vItems.kondisi_kode;
						}
					}
					//--END TEKS--/

					//--COLOR--/
					if(vItems.kondisi_simbol==="COLOR") {
						if(vItems.surface === "OCCUSAL")
							vSimbolcoloroccusal	+= " class"+(vItems.kondisi_kode||"").toLowerCase();
						else if(vItems.surface === "MESIAL")
							vSimbolcolormesial	+= " class"+(vItems.kondisi_kode||"").toLowerCase();
						else if(vItems.surface === "DISTAL")
							vSimbolcolordistal	+= " class"+(vItems.kondisi_kode||"").toLowerCase();
						else if(vItems.surface === "LINGUAL")
							vSimbolcolorlingual	+= " class"+(vItems.kondisi_kode||"").toLowerCase();
						else if(vItems.surface === "VESTIBULAR")
							vSimbolcolorvestibular	+= " class"+(vItems.kondisi_kode||"").toLowerCase();
						else
							vSimbolcolorall	+= " class"+(vItems.kondisi_kode||"").toLowerCase();
					}
					//--END COLOR--/

					//--IMAGE--/
					if(vItems.kondisi_simbol==="IMAGE") {
						let vPoints;
						if((vItems.kondisi_kode||"").toLowerCase()==="abu") {
							vPoints		= "0 30,44 30,44 41, 0 41";
							vSimbolimageArr.push(
							<polygon points={vPoints} alt="IMAGE" stroke-width="5" stroke="black" fill="gray"/>
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="cfr") {
							vPoints		= "58";
							vSimbolimageArr.push(
								<text x="22" y={vPoints} 
									textAnchor="middle" fontSize="60" font-weight="bold">#</text>
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="mam") {
							vPoints		= "68";
							vSimbolimageArr.push(
							<text x="22" y={vPoints} textAnchor="middle" fontSize="110">&times;</text>
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="meb") {
							vPoints		= "0 30,44 30,44 41, 0 41";
							vSimbolimageArr.push(
							<polygon points={vPoints} alt="IMAGE" stroke-width="5" stroke="black" fill="red"/>
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="mis") {
							vPoints		= "68";
							vSimbolimageArr.push(
							<text x="22" y={vPoints} textAnchor="middle" fontSize="110">&times;</text>
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="mpm") {
							vSimbolimageArr.push(
								<circle cx="22" cy="35" r="20" fill="none" stroke="black" stroke-width="3"/>
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="nvt") {
							vPoints	= (posisi === "ATAS") ? "14 58, 30 58, 22 70" : "14 12, 30 12, 22 0";

							vSimbolimageArr.push(
							<polygon points={vPoints} alt="IMAGE" stroke-width="2" fill="none" stroke="black"/> 
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="pob") {
							vPoints		= "0 30,44 30,44 41, 0 41";
							vSimbolimageArr.push(
							<polygon points={vPoints} alt="IMAGE" stroke-width="5" stroke="black" fill="green"/>
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase(	)==="pon") {
							vPoints		= "68";
							vSimbolimageArr.push(
							<text x="22" y={vPoints} textAnchor="middle" fontSize="110">&times;</text>
							)//>
							vSimbolimageArr.push(
							<polygon points="3 15, 42 15, 42 55, 3 55" stroke="black" stroke-width="5" fill="none"/>
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="rct") {
							vPoints	= (posisi === "ATAS") ? "14 58, 30 58, 22 70" : "14 12, 30 12, 22 0";

							vSimbolimageArr.push(
							<polygon points={vPoints} alt="IMAGE" stroke-width="1" fill="black" stroke="black"/> 
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="rrx") {
							vPoints		= "60";
							vSimbolimageArr.push(
							<text x="22" y={vPoints} textAnchor="middle" fontSize="65">V</text>
							)//>
						}
					}
					//--END IMAGE--/
				});
			}
			if(vKondisiinputArr.length > 0) {
				let vTekstmp	= "";
				vKondisiinputArr.map(vItems => {
					//--TEKS--/
					if(vItems.kondisi_simbol==="TEKS") {
						if(vTekstmp	=== "") {
							vSimbolteks 	= vItems.kondisi_kode;
							vTekstmp		= vItems.kondisi_kode;
						}
					}
					//--END TEKS--/

					//--COLOR--/
					if(vItems.kondisi_simbol==="COLOR") {
						if(vItems.surface === "OCCUSAL")
							vSimbolcoloroccusal	+= " class"+(vItems.kondisi_kode||"").toLowerCase();
						else if(vItems.surface === "MESIAL")
							vSimbolcolormesial	+= " class"+(vItems.kondisi_kode||"").toLowerCase();
						else if(vItems.surface === "DISTAL")
							vSimbolcolordistal	+= " class"+(vItems.kondisi_kode||"").toLowerCase();
						else if(vItems.surface === "LINGUAL")
							vSimbolcolorlingual	+= " class"+(vItems.kondisi_kode||"").toLowerCase();
						else if(vItems.surface === "VESTIBULAR")
							vSimbolcolorvestibular	+= " class"+(vItems.kondisi_kode||"").toLowerCase();
						else
							vSimbolcolorall	+= " class"+(vItems.kondisi_kode||"").toLowerCase();
					}
					//--END COLOR--/

					//--IMAGE--/
					if(vItems.kondisi_simbol==="IMAGE") {
						let vPoints;
						if((vItems.kondisi_kode||"").toLowerCase()==="abu") {
							vPoints		= "0 30,44 30,44 41, 0 41";
							vSimbolimageArr.push(
							<polygon points={vPoints} alt="IMAGE" stroke-width="5" stroke="black" fill="gray"/>
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="cfr") {
							vPoints		= "58";
							vSimbolimageArr.push(
								<text x="22" y={vPoints} 
									textAnchor="middle" fontSize="60" font-weight="bold">#</text>
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="mam") {
							vPoints		= "68";
							vSimbolimageArr.push(
							<text x="22" y={vPoints} textAnchor="middle" fontSize="110">&times;</text>
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="meb") {
							vPoints		= "0 30,44 30,44 41, 0 41";
							vSimbolimageArr.push(
							<polygon points={vPoints} alt="IMAGE" stroke-width="5" stroke="black" fill="red"/>
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="mis") {
							vPoints		= "68";
							vSimbolimageArr.push(
							<text x="22" y={vPoints} textAnchor="middle" fontSize="110">&times;</text>
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="mpm") {
							vSimbolimageArr.push(
								<circle cx="22" cy="35" r="20" fill="none" stroke="black" stroke-width="3"/>
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="nvt") {
							vPoints	= (posisi === "ATAS") ? "14 58, 30 58, 22 70" : "14 12, 30 12, 22 0";

							vSimbolimageArr.push(
							<polygon points={vPoints} alt="IMAGE" stroke-width="2" fill="none" stroke="black"/> 
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="pob") {
							vPoints		= "0 30,44 30,44 41, 0 41";
							vSimbolimageArr.push(
							<polygon points={vPoints} alt="IMAGE" stroke-width="5" stroke="black" fill="green"/>
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase(	)==="pon") {
							vPoints		= "68";
							vSimbolimageArr.push(
							<text x="22" y={vPoints} textAnchor="middle" fontSize="110">&times;</text>
							)//>
							vSimbolimageArr.push(
							<polygon points="3 15, 42 15, 42 55, 3 55" stroke="black" stroke-width="5" fill="none"/>
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="rct") {
							vPoints	= (posisi === "ATAS") ? "14 58, 30 58, 22 70" : "14 12, 30 12, 22 0";

							vSimbolimageArr.push(
							<polygon points={vPoints} alt="IMAGE" stroke-width="1" fill="black" stroke="black"/> 
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="rrx") {
							vPoints		= "60";
							vSimbolimageArr.push(
							<text x="22" y={vPoints} textAnchor="middle" fontSize="65">V</text>
							)//>
						}
					}
					//--END IMAGE--/
				});
			}

			//console.log("(MyCardodontogram - ContentTooth) ["+number+"] vKondisihistoriArr => "+JSON.stringify(vKondisihistoriArr))
			//console.log("(MyCardodontogram - ContentTooth) ["+number+"] vSimbolteks => "+(vSimbolteks))

			if(isMobile) {
				if(posisi === "ATAS" && jenis === "GIGI_SERI") return (
				<svg width="44" height="70" viewBox="0 0 44 70" className="classtooth-svg"
					onClick={() => hdlKlikGigi(number)}>
					<text x="22" y="11" textAnchor="middle" fontSize="12">{number}</text>
					
					{vSimbolteks !== "" && (
					<text x="22" y="67" textAnchor="middle" fontSize="13" font-weight="bold">{vSimbolteks}</text>
					)}

					<polygon points="3 15, 41 15, 41 55, 3 55" alt="ALL" className={"classall"+vSimbolcolorall}/>

					{vSimbolimageArr.map(vItems => vItems)}

					<polygon points="0 13, 44 13, 32 35, 12 35" alt="ATAS"
						className={"classsurfacem"+vSimbolcolorvestibular}/>

					<polygon points="32 35, 44 13, 44 57" alt="KANAN"
						className={"classsurfacem"+(sisi==="KANAN" ? vSimbolcolormesial : vSimbolcolordistal)}/>

					<polygon points="12 35, 32 35, 44 57, 0 57" alt="BAWAH"
						className={"classsurfacem"+vSimbolcolorlingual}/>

					<polygon points="0 13, 12 35, 0 57" alt="KIRI"
						className={"classsurfacem"+(sisi!=="KANAN" ? vSimbolcolormesial : vSimbolcolordistal)}/>
					
					<polygon points="0 13, 44 13, 44 57, 0 57" alt="ALL" className="classklikall"/>
				</svg>
				)//->


				if(posisi === "BAWAH" && jenis === "GIGI_SERI") return (
				<svg width="44" height="70" viewBox="0 0 44 70" className="classtooth-svg"
					onClick={() => hdlKlikGigi(number)}>
					<text x="22" y="70" textAnchor="middle" fontSize="12">{number}</text>

					{vSimbolteks !== "" && (
					<text x="22" y="11" textAnchor="middle" fontSize="13" font-weight="bold">{vSimbolteks}</text>
					)}

					<polygon points="3 15, 41 15, 41 55, 3 55" alt="ALL" className={"classall"+vSimbolcolorall}/>

					{vSimbolimageArr.map(vItems => vItems)}

					<polygon points="0 13, 44 13, 32 35, 12 35" alt="ATAS"
						className={"classsurfacem"+vSimbolcolorlingual}/>

					<polygon points="32 35, 44 13, 44 57" alt="KANAN"
						className={"classsurfacem"+(sisi!=="KANAN" ? vSimbolcolormesial : vSimbolcolordistal)}/>

					<polygon points="12 35, 32 35, 44 57, 0 57" alt="BAWAH"
						className={"classsurfacem"+vSimbolcolorvestibular}/>

					<polygon points="0 13, 12 35, 0 57" alt="KIRI"
						className={"classsurfacem"+(sisi==="KANAN" ? vSimbolcolormesial : vSimbolcolordistal)}/>
					
					<polygon points="0 13, 44 13, 44 57, 0 57" alt="ALL" className="classklikall"/>
				</svg>
				)//->

				if(posisi === "BAWAH" && jenis === "GIGI_GERAHAM") return (
				<svg width="44" height="70" viewBox="0 0 44 70" className="classtooth-svg"
					onClick={() => hdlKlikGigi(number,'LINGUAL')}>
					<text x="22" y="70" textAnchor="middle" fontSize="12">{number}</text>
					
					{vSimbolteks !== "" && (
					<text x="22" y="11" textAnchor="middle" fontSize="13" font-weight="bold">{vSimbolteks}</text>
					)}
						
					<polygon points="3 16, 41 16, 41 55, 3 55" alt="ALL" className={"classall"+vSimbolcolorall}/>

					{vSimbolimageArr.map(vItems => vItems)}

					<polygon points="0 13, 44 13, 32 25, 12 25"
						className={"classsurfacem"+vSimbolcolorlingual}/>

					<polygon points="44 13, 44 57, 32 46, 32 25"
						className={"classsurfacem"+(sisi!=="KANAN" ? vSimbolcolormesial : vSimbolcolordistal)}/>

					<polygon points="12 46, 32 46, 44 57, 0 57"
						className={"classsurfacem"+vSimbolcolorvestibular}/>

					<polygon points="0 13, 12 25, 12 46, 0 57"
						className={"classsurfacem"+(sisi==="KANAN" ? vSimbolcolormesial : vSimbolcolordistal)}/>

					<polygon points="12 25, 32 25, 32 46, 12 46"
						className={"classsurfacem"+vSimbolcoloroccusal} />

					<polygon points="0 13, 44 13, 44 57, 0 57" alt="ALL" className="classklikall"/>
				</svg>
				)//->

				return (
				<svg width="44" height="70" viewBox="0 0 44 70" className="classtooth-svg"
					onClick={() => hdlKlikGigi(number)}>
					<text x="22" y="11" textAnchor="middle" fontSize="12">{number}</text>
					
					{vSimbolteks !== "" && (
					<text x="22" y="67" textAnchor="middle" fontSize="13" font-weight="bold">{vSimbolteks}</text>
					)}

					<polygon points="3 15, 41 15, 41 55, 3 55" alt="ALL" className={"classall"+vSimbolcolorall}/>

					{vSimbolimageArr.map(vItems => vItems)}

					<polygon points="0 13, 44 13, 32 25, 12 25"
						className={"classsurfacem"+vSimbolcolorvestibular}/>

					<polygon points="44 13, 44 57, 32 46, 32 25"
						className={"classsurfacem"+(sisi==="KANAN" ? vSimbolcolormesial : vSimbolcolordistal)} />

					<polygon points="12 46, 32 46, 44 57, 0 57"
						className={"classsurfacem"+vSimbolcolorlingual}/>

					<polygon points="0 13, 12 25, 12 46, 0 57"
						className={"classsurfacem"+(sisi!=="KANAN" ? vSimbolcolormesial : vSimbolcolordistal)}/>

					<polygon points="12 25, 32 25, 32 46, 12 46"
						className={"classsurfacem"+vSimbolcoloroccusal}/>

					<polygon points="0 13, 44 13, 44 57, 0 57" alt="ALL" className="classklikall"/>
				</svg>
				)//->
			}//-*/

			if(posisi === "ATAS" && jenis === "GIGI_SERI") return (
			<svg width="44" height="70" viewBox="0 0 44 70" className="classtooth-svg">
				<text x="22" y="11" textAnchor="middle" fontSize="12">{number}</text>
				
				{vSimbolteks !== "" && (
					<text x="22" y="67" textAnchor="middle" fontSize="13" font-weight="bold">{vSimbolteks}</text>
				)}

				<polygon points="3 15, 41 15, 41 55, 3 55" alt="ALL" className={"classall"+vSimbolcolorall}/>

				{vSimbolimageArr.map(vItems => vItems)}

				<polygon points="0 13, 44 13, 32 35, 12 35" alt="ATAS"
					className={"classsurface"+vSimbolcolorvestibular}
					onClick={() => hdlKlikGigi(number,'VESTIBULAR')} />

				<polygon points="32 35, 44 13, 44 57" alt="KANAN"
					className={"classsurface"+(sisi==="KANAN" ? vSimbolcolormesial : vSimbolcolordistal)}
					onClick={() => hdlKlikGigi(number,(sisi==="KANAN" ? "MESIAL" : "DISTAL"))} />

				<polygon points="12 35, 32 35, 44 57, 0 57" alt="BAWAH"
					className={"classsurface"+vSimbolcolorlingual}
					onClick={() => hdlKlikGigi(number,'LINGUAL')} />

				<polygon points="0 13, 12 35, 0 57" alt="KIRI"
					className={"classsurface"+(sisi!=="KANAN" ? vSimbolcolormesial : vSimbolcolordistal)}
					onClick={() => hdlKlikGigi(number,(sisi!=="KANAN" ? "MESIAL" : "DISTAL"))} />
			</svg>
			)//->

			if(posisi === "BAWAH" && jenis === "GIGI_SERI") return (
			<svg width="44" height="70" viewBox="0 0 44 70" className="classtooth-svg">
				<text x="22" y="70" textAnchor="middle" fontSize="12">{number}</text>

				{vSimbolteks !== "" && (
				<text x="22" y="11" textAnchor="middle" fontSize="13" font-weight="bold">{vSimbolteks}</text>
				)}

				<polygon points="3 15, 41 15, 41 55, 3 55" alt="ALL" className={"classall"+vSimbolcolorall}/>

				{vSimbolimageArr.map(vItems => vItems)}

				<polygon points="0 13, 44 13, 32 35, 12 35" alt="ATAS"
					className={"classsurface"+vSimbolcolorlingual}
					onClick={() => hdlKlikGigi(number,'LINGUAL')} />

				<polygon points="32 35, 44 13, 44 57" alt="KANAN"
					className={"classsurface"+(sisi!=="KANAN" ? vSimbolcolormesial : vSimbolcolordistal)}
					onClick={() => hdlKlikGigi(number,(sisi!=="KANAN" ? "MESIAL" : "DISTAL"))} />

				<polygon points="12 35, 32 35, 44 57, 0 57" alt="BAWAH"
					className={"classsurface"+vSimbolcolorvestibular}
					onClick={() => hdlKlikGigi(number,'VESTIBULAR')} />

				<polygon points="0 13, 12 35, 0 57" alt="KIRI"
					className={"classsurface"+(sisi==="KANAN" ? vSimbolcolormesial : vSimbolcolordistal)}
					onClick={() => hdlKlikGigi(number,(sisi==="KANAN" ? "MESIAL" : "DISTAL"))} />
			</svg>
			)//->

			if(posisi === "BAWAH" && jenis === "GIGI_GERAHAM") return (
			<svg width="44" height="70" viewBox="0 0 44 70" className="classtooth-svg">
				<text x="22" y="70" textAnchor="middle" fontSize="12">{number}</text>
				
				{vSimbolteks !== "" && (
				<text x="22" y="11" textAnchor="middle" fontSize="13" font-weight="bold">{vSimbolteks}</text>
				)}
					
				<polygon points="3 15, 41 15, 41 55, 3 55" alt="ALL" className={"classall"+vSimbolcolorall}/>

				{vSimbolimageArr.map(vItems => vItems)}

				<polygon points="0 13, 44 13, 32 25, 12 25"
					className={"classsurface"+vSimbolcolorlingual}
					onClick={() => hdlKlikGigi(number,'LINGUAL')} />

				<polygon points="44 13, 44 57, 32 46, 32 25"
					className={"classsurface"+(sisi!=="KANAN" ? vSimbolcolormesial : vSimbolcolordistal)}
					onClick={() => hdlKlikGigi(number,(sisi!=="KANAN" ? "MESIAL" : "DISTAL"))} />

				<polygon points="12 46, 32 46, 44 57, 0 57"
					className={"classsurface"+vSimbolcolorvestibular}
					onClick={() => hdlKlikGigi(number,'VESTIBULAR')} />

				<polygon points="0 13, 12 25, 12 46, 0 57"
					className={"classsurface"+(sisi==="KANAN" ? vSimbolcolormesial : vSimbolcolordistal)}
					onClick={() => hdlKlikGigi(number,(sisi==="KANAN" ? "MESIAL" : "DISTAL"))} />

				<polygon points="12 25, 32 25, 32 46, 12 46"
					className={"classsurface ${vSimbolcoloroccusal}"}
					onClick={() => hdlKlikGigi(number,'OCCUSAL')} />
			</svg>
			)//->

			return (
			<svg width="44" height="70" viewBox="0 0 44 70" className="classtooth-svg">
				<text x="22" y="11" textAnchor="middle" fontSize="12">{number}</text>
				
				{vSimbolteks !== "" && (
					<text x="22" y="67" textAnchor="middle" fontSize="13" font-weight="bold">{vSimbolteks}</text>
				)}

				<polygon points="3 15, 41 15, 41 55, 3 55" alt="ALL" className={"classall"+vSimbolcolorall}/>

				{vSimbolimageArr.map(vItems => vItems)}

				<polygon points="0 13, 44 13, 32 25, 12 25"
					className={"classsurface"+vSimbolcolorvestibular}
					onClick={() => hdlKlikGigi(number,'VESTIBULAR')} />

				<polygon points="44 13, 44 57, 32 46, 32 25"
					className={"classsurface"+(sisi==="KANAN" ? vSimbolcolormesial : vSimbolcolordistal)}
					onClick={() => hdlKlikGigi(number,(sisi==="KANAN" ? "MESIAL" : "DISTAL"))} />

				<polygon points="12 46, 32 46, 44 57, 0 57"
					className={"classsurface"+vSimbolcolorlingual}
					onClick={() => hdlKlikGigi(number,'LINGUAL')} />

				<polygon points="0 13, 12 25, 12 46, 0 57"
					className={"classsurface"+(sisi!=="KANAN" ? vSimbolcolormesial : vSimbolcolordistal)}
					onClick={() => hdlKlikGigi(number,(sisi!=="KANAN" ? "MESIAL" : "DISTAL"))} />

				<polygon points="12 25, 32 25, 32 46, 12 46"
					className={"classsurface ${vSimbolcoloroccusal}"}
					onClick={() => hdlKlikGigi(number,'OCCUSAL')} />
			</svg>
			)//->
		});
	},[uDatagigiArr.length,
	uDatahistoriArr.length,
	uDatainputArr.length,
	uISDatainputupdate]);

	const ContentTooth = React.useMemo(()=>{ 
		return React.memo(({ number, posisi, sisi, jenis}) => {
			const vKondisihistoriArr= uDatahistoriArr.filter(vItems=> parseInt(vItems.nomor_gigi)===parseInt(number));
			const vKondisiinputArr	= uDatainputArr.filter(vItems=> 
				parseInt(vItems.nomor_gigi)===parseInt(number)	&&
				(vItems.status_hapus||"TIDAK")!=="YA"
				);

			let vSimbolteks = "";
			let vSimbolimageArr			= [];
			let vSimbolcolormesial 		= "";
			let vSimbolcolordistal 		= "";
			let vSimbolcolorlingual 	= "";
			let vSimbolcolorvestibular 	= "";
			let vSimbolcoloroccusal 	= "";
			let vSimbolcolorall = "";
			let vKondisiidx;

			if(vKondisihistoriArr.length > 0) {
				let vTekstmp	= "";
				vKondisihistoriArr.map(vItems => {
					//--TEKS--/
					if(vItems.kondisi_simbol==="TEKS") {
						if(vTekstmp	=== "") {
							vSimbolteks 	= vItems.kondisi_kode;
							vTekstmp		= vItems.kondisi_kode;
						}
					}
					//--END TEKS--/

					//--COLOR--/
					if(vItems.kondisi_simbol==="COLOR") {
						if(vItems.surface === "OCCUSAL")
							vSimbolcoloroccusal	+= " class"+(vItems.kondisi_kode||"").toLowerCase();
						else if(vItems.surface === "MESIAL")
							vSimbolcolormesial	+= " class"+(vItems.kondisi_kode||"").toLowerCase();
						else if(vItems.surface === "DISTAL")
							vSimbolcolordistal	+= " class"+(vItems.kondisi_kode||"").toLowerCase();
						else if(vItems.surface === "LINGUAL")
							vSimbolcolorlingual	+= " class"+(vItems.kondisi_kode||"").toLowerCase();
						else if(vItems.surface === "VESTIBULAR")
							vSimbolcolorvestibular	+= " class"+(vItems.kondisi_kode||"").toLowerCase();
						else
							vSimbolcolorall	+= " class"+(vItems.kondisi_kode||"").toLowerCase();
					}
					//--END COLOR--/

					//--IMAGE--/
					if(vItems.kondisi_simbol==="IMAGE") {
						let vPoints;
						if((vItems.kondisi_kode||"").toLowerCase()==="abu") {
							vPoints		= "0 33,48 33,48 45, 0 45";
							vSimbolimageArr.push(
							<polygon points={vPoints} alt="IMAGE" stroke-width="5" stroke="black" fill="gray"/>
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="cfr") {
							vPoints		= "62";
							vSimbolimageArr.push(
								<text x="24" y={vPoints} 
									textAnchor="middle" fontSize="60" font-weight="bold">#</text>
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="mam") {
							vPoints		= "73";
							vSimbolimageArr.push(
							<text x="24" y={vPoints} textAnchor="middle" fontSize="115">&times;</text>
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="meb") {
							vPoints		= "0 33,48 33,48 45, 0 45";
							vSimbolimageArr.push(
							<polygon points={vPoints} alt="IMAGE" stroke-width="5" stroke="black" fill="red"/>
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="mis") {
							vPoints		= "73";
							vSimbolimageArr.push(
							<text x="24" y={vPoints} textAnchor="middle" fontSize="115">&times;</text>
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="mpm") {
							vSimbolimageArr.push(
								<circle cx="24" cy="39" r="22" fill="none" stroke="black" stroke-width="3"/>
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="nvt") {
							vPoints	= (posisi === "ATAS") ? "16 64, 32 64, 24 78" : "16 14, 32 14, 24 0";

							vSimbolimageArr.push(
							<polygon points={vPoints} alt="IMAGE" stroke-width="2" fill="none" stroke="black"/> 
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="pob") {
							vPoints		= "0 33, 48 33, 48 45, 0 45";
							vSimbolimageArr.push(
							<polygon points={vPoints} alt="IMAGE" stroke-width="5" stroke="black" fill="green"/>
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="pon") {
							vPoints		= "73";
							vSimbolimageArr.push(
							<text x="24" y={vPoints} textAnchor="middle" fontSize="115">&times;</text>
							)//>
							vSimbolimageArr.push(
							<polygon points="3 15, 45 15, 45 63, 3 63" stroke="black" stroke-width="5" fill="none"/>
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="rct") {
							vPoints	= (posisi === "ATAS") ? "16 64, 32 64, 24 78" : "16 14, 32 14, 24 0";

							vSimbolimageArr.push(
							<polygon points={vPoints} alt="IMAGE" stroke-width="1" fill="black" stroke="black"/> 
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="rrx") {
							vPoints		= "62";
							vSimbolimageArr.push(
							<text x="24" y={vPoints} textAnchor="middle" fontSize="65">V</text>
							)//>
						}
					}
					//--END IMAGE--/
				});
			}
			if(vKondisiinputArr.length > 0) {
				let vTekstmp	= "";
				vKondisiinputArr.map(vItems => {
					//--TEKS--/
					if(vItems.kondisi_simbol==="TEKS") {
						if(vTekstmp	=== "") {
							vSimbolteks 	= vItems.kondisi_kode;
							vTekstmp		= vItems.kondisi_kode;
						}
					}
					//--END TEKS--/

					//--COLOR--/
					if(vItems.kondisi_simbol==="COLOR") {
						if(vItems.surface === "OCCUSAL")
							vSimbolcoloroccusal	+= " class"+(vItems.kondisi_kode||"").toLowerCase();
						else if(vItems.surface === "MESIAL")
							vSimbolcolormesial	+= " class"+(vItems.kondisi_kode||"").toLowerCase();
						else if(vItems.surface === "DISTAL")
							vSimbolcolordistal	+= " class"+(vItems.kondisi_kode||"").toLowerCase();
						else if(vItems.surface === "LINGUAL")
							vSimbolcolorlingual	+= " class"+(vItems.kondisi_kode||"").toLowerCase();
						else if(vItems.surface === "VESTIBULAR")
							vSimbolcolorvestibular	+= " class"+(vItems.kondisi_kode||"").toLowerCase();
						else
							vSimbolcolorall	+= " class"+(vItems.kondisi_kode||"").toLowerCase();
					}
					//--END COLOR--/

					//--IMAGE--/
					if(vItems.kondisi_simbol==="IMAGE") {
						let vPoints;
						if((vItems.kondisi_kode||"").toLowerCase()==="abu") {
							vPoints		= "0 33,48 33,48 45, 0 45";
							vSimbolimageArr.push(
							<polygon points={vPoints} alt="IMAGE" stroke-width="5" stroke="black" fill="gray"/>
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="cfr") {
							vPoints		= "62";
							vSimbolimageArr.push(
								<text x="24" y={vPoints} 
									textAnchor="middle" fontSize="60" font-weight="bold">#</text>
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="mam") {
							vPoints		= "73";
							vSimbolimageArr.push(
							<text x="24" y={vPoints} textAnchor="middle" fontSize="115">&times;</text>
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="meb") {
							vPoints		= "0 33,48 33,48 45, 0 45";
							vSimbolimageArr.push(
							<polygon points={vPoints} alt="IMAGE" stroke-width="5" stroke="black" fill="red"/>
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="mis") {
							vPoints		= "73";
							vSimbolimageArr.push(
							<text x="24" y={vPoints} textAnchor="middle" fontSize="115">&times;</text>
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="mpm") {
							vSimbolimageArr.push(
								<circle cx="24" cy="39" r="22" fill="none" stroke="black" stroke-width="3"/>
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="nvt") {
							vPoints	= (posisi === "ATAS") ? "16 64, 32 64, 24 78" : "16 14, 32 14, 24 0";

							vSimbolimageArr.push(
							<polygon points={vPoints} alt="IMAGE" stroke-width="2" fill="none" stroke="black"/> 
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="pob") {
							vPoints		= "0 33,48 33,48 45, 0 45";
							vSimbolimageArr.push(
							<polygon points={vPoints} alt="IMAGE" stroke-width="5" stroke="black" fill="green"/>
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase(	)==="pon") {
							vPoints		= "73";
							vSimbolimageArr.push(
							<text x="24" y={vPoints} textAnchor="middle" fontSize="115">&times;</text>
							)//>
							vSimbolimageArr.push(
							<polygon points="3 15, 45 15, 45 63, 3 63" stroke="black" stroke-width="5" fill="none"/>
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="rct") {
							vPoints	= (posisi === "ATAS") ? "16 64, 32 64, 24 75" : "16 14, 32 14, 24 0";

							vSimbolimageArr.push(
							<polygon points={vPoints} alt="IMAGE" stroke-width="1" fill="black" stroke="black"/> 
							)//>
						}
						if((vItems.kondisi_kode||"").toLowerCase()==="rrx") {
							vPoints		= "62";
							vSimbolimageArr.push(
							<text x="24" y={vPoints} textAnchor="middle" fontSize="65">V</text>
							)//>
						}
					}
					//--END IMAGE--/
				});
			}

			//console.log("(MyCardodontogram - ContentTooth) ["+number+"] vKondisihistoriArr => "+JSON.stringify(vKondisihistoriArr))
			//console.log("(MyCardodontogram - ContentTooth) ["+number+"] vSimbolteks => "+(vSimbolteks))

			if(isMobile) {
				if(posisi === "ATAS" && jenis === "GIGI_SERI") return (
				<svg width="48" height="78" viewBox="0 0 48 78" className="classtooth-svg"
					onClick={() => hdlKlikGigi(number)}>
					<text x="23" y="13" textAnchor="middle" fontSize="13">{number}</text>
					
					{vSimbolteks !== "" && (
					<text x="23" y="74" textAnchor="middle" fontSize="15" font-weight="bold">{vSimbolteks}</text>
					)}

					<polygon points="3 15, 45 15, 45 63, 3 63" alt="ALL" className={"classall"+vSimbolcolorall}/>

					{vSimbolimageArr.map(vItems => vItems)}

					<polygon points="0 15, 48 15, 34 39, 14 39" alt="ATAS"
						className={"classsurfacem"+vSimbolcolorvestibular}/>

					<polygon points="34 39, 48 15, 48 63" alt="KANAN"
						className={"classsurfacem"+(sisi==="KANAN" ? vSimbolcolormesial : vSimbolcolordistal)}/>

					<polygon points="14 39, 34 39, 48 63, 0 63" alt="BAWAH"
						className={"classsurfacem"+vSimbolcolorlingual}/>

					<polygon points="0 15, 14 39, 0 63" alt="KIRI"
						className={"classsurfacem"+(sisi!=="KANAN" ? vSimbolcolormesial : vSimbolcolordistal)}/>
					
					<polygon points="0 13, 44 13, 44 57, 0 57" alt="ALL" className="classklikall"/>
				</svg>
				)//->


				if(posisi === "BAWAH" && jenis === "GIGI_SERI") return (
				<svg width="48" height="78" viewBox="0 0 48 78" className="classtooth-svg"
					onClick={() => hdlKlikGigi(number)}>
					<text x="23" y="74" textAnchor="middle" fontSize="13">{number}</text>

					{vSimbolteks !== "" && (
					<text x="23" y="12" textAnchor="middle" fontSize="15" font-weight="bold">{vSimbolteks}</text>
					)}

					<polygon points="3 15, 45 15, 45 63, 3 63" alt="ALL" className={"classall"+vSimbolcolorall}/>

					{vSimbolimageArr.map((vItems,vKeys) => (<React.Fragment key={vKeys}>{vItems}</React.Fragment>))}

					<polygon points="0 15, 48 15, 34 39, 14 39"
						className={"classsurfacem"+vSimbolcolorlingual}/>

					<polygon points="34 39, 48 15, 48 63"
						className={"classsurfacem"+(sisi!=="KANAN" ? vSimbolcolormesial : vSimbolcolordistal)}/>

					<polygon points="14 39, 34 39, 48 63, 0 63"
						className={"classsurfacem"+vSimbolcolorvestibular}/>

					<polygon points="0 15, 14 39, 0 63"
						className={"classsurfacem"+(sisi==="KANAN" ? vSimbolcolormesial : vSimbolcolordistal)}/>

					<polygon points="0 13, 44 13, 44 57, 0 57" alt="ALL" className="classklikall"/>
				</svg>
				)//->

				if(posisi === "BAWAH" && jenis === "GIGI_GERAHAM") return (
				<svg width="48" height="78" viewBox="0 0 48 78" className="classtooth-svg"
					onClick={() => hdlKlikGigi(number,'LINGUAL')}>
					<text x="23" y="74" textAnchor="middle" fontSize="13">{number}</text>
					
					{vSimbolteks !== "" && (
					<text x="23" y="12" textAnchor="middle" fontSize="15" font-weight="bold">{vSimbolteks}</text>
					)}
						
					<polygon points="3 15, 45 15, 45 63, 3 63" alt="ALL" className={"classall"+vSimbolcolorall}/>

					{vSimbolimageArr.map(vItems => vItems)}

					<polygon points="0 15, 48 15, 34 29, 14 29"
						className={"classsurfacem"+vSimbolcolorlingual}/>

					<polygon points="48 15, 48 63, 34 49, 34 29"
						className={"classsurfacem"+(sisi!=="KANAN" ? vSimbolcolormesial : vSimbolcolordistal)}/>

					<polygon points="14 49, 34 49, 48 63, 0 63"
						className={"classsurfacem"+vSimbolcolorvestibular}/>

					<polygon points="0 15, 14 29, 14 49, 0 63"
						className={"classsurfacem"+(sisi==="KANAN" ? vSimbolcolormesial : vSimbolcolordistal)}/>

					<polygon points="14 29, 34 29, 34 49, 14 49"
						className={"classsurfacem"+vSimbolcoloroccusal} />

					<polygon points="0 13, 44 13, 44 57, 0 57" alt="ALL" className="classklikall"/>
				</svg>
				)//->

				return (
				<svg width="48" height="78" viewBox="0 0 48 78" className="classtooth-svg"
					onClick={() => hdlKlikGigi(number)}>
					<text x="23" y="13" textAnchor="middle" fontSize="13">{number}</text>
					
					{vSimbolteks !== "" && (
					<text x="23" y="74" textAnchor="middle" fontSize="15" font-weight="bold">{vSimbolteks}</text>
					)}

					<polygon points="3 15, 45 15, 45 63, 3 63" alt="ALL" className={"classall"+vSimbolcolorall}/>

					{vSimbolimageArr.map((vItems,vKeys) => (<React.Fragment key={vKeys}>{vItems}</React.Fragment>))}

					<polygon points="0 15, 48 15, 34 29, 14 29"
						className={"classsurfacem"+vSimbolcolorvestibular}/>

					<polygon points="48 15, 48 63, 34 49, 34 29"
						className={"classsurfacem"+(sisi==="KANAN" ? vSimbolcolormesial : vSimbolcolordistal)} />

					<polygon points="14 49, 34 49, 48 63, 0 63"
						className={"classsurfacem"+vSimbolcolorlingual}/>

					<polygon points="0 15, 14 29, 14 49, 0 63"
						className={"classsurfacem"+(sisi!=="KANAN" ? vSimbolcolormesial : vSimbolcolordistal)}/>

					<polygon points="14 29, 34 29, 34 49, 14 49"
						className={"classsurfacem"+vSimbolcoloroccusal}/>

					<polygon points="0 13, 44 13, 44 57, 0 57" alt="ALL" className="classklikall"/>
				</svg>
				)//->
			}//-*/

			if(posisi === "ATAS" && jenis === "GIGI_SERI") return (
			<svg width="48" height="78" viewBox="0 0 48 78" className="classtooth-svg">
				<text x="23" y="13" textAnchor="middle" fontSize="13">{number}</text>
				
				{vSimbolteks !== "" && (
				<text x="23" y="74" textAnchor="middle" fontSize="15" font-weight="bold">{vSimbolteks}</text>
				)}

				<polygon points="3 15, 45 15, 45 63, 3 63" alt="ALL" className={"classall"+vSimbolcolorall}/>

				{vSimbolimageArr.map((vItems,vKeys) => (<React.Fragment key={vKeys}>{vItems}</React.Fragment>))}

				<polygon points="0 15, 48 15, 34 39, 14 39" alt="ATAS"
					className={"classsurface"+vSimbolcolorvestibular}
					onClick={() => hdlKlikGigi(number,'VESTIBULAR')} />

				<polygon points="34 39, 48 15, 48 63" alt="KANAN"
					className={"classsurface"+(sisi==="KANAN" ? vSimbolcolormesial : vSimbolcolordistal)}
					onClick={() => hdlKlikGigi(number,(sisi==="KANAN" ? "MESIAL" : "DISTAL"))} />

				<polygon points="14 39, 34 39, 48 63, 0 63" alt="BAWAH"
					className={"classsurface"+vSimbolcolorlingual}
					onClick={() => hdlKlikGigi(number,'LINGUAL')} />

				<polygon points="0 15, 14 39, 0 63" alt="KIRI"
					className={"classsurface"+(sisi!=="KANAN" ? vSimbolcolormesial : vSimbolcolordistal)}
					onClick={() => hdlKlikGigi(number,(sisi!=="KANAN" ? "MESIAL" : "DISTAL"))} />
			</svg>
			)//->

			if(posisi === "BAWAH" && jenis === "GIGI_SERI") return (
			<svg width="48" height="78" viewBox="0 0 48 78" className="classtooth-svg">
				<text x="23" y="74" textAnchor="middle" fontSize="13">{number}</text>

				{vSimbolteks !== "" && (
				<text x="23" y="12" textAnchor="middle" fontSize="15" font-weight="bold">{vSimbolteks}</text>
				)}

				<polygon points="3 15, 45 15, 45 63, 3 63" alt="ALL" className={"classall"+vSimbolcolorall}/>

				{vSimbolimageArr.map((vItems,vKeys) => (<React.Fragment key={vKeys}>{vItems}</React.Fragment>))}

				<polygon points="0 15, 48 15, 34 39, 14 39"
					className={"classsurface"+vSimbolcolorlingual}
					onClick={() => hdlKlikGigi(number,'LINGUAL')} />

				<polygon points="34 39, 48 15, 48 63"
					className={"classsurface"+(sisi!=="KANAN" ? vSimbolcolormesial : vSimbolcolordistal)}
					onClick={() => hdlKlikGigi(number,(sisi!=="KANAN" ? "MESIAL" : "DISTAL"))} />

				<polygon points="14 39, 34 39, 48 63, 0 63"
					className={"classsurface"+vSimbolcolorvestibular}
					onClick={() => hdlKlikGigi(number,'VESTIBULAR')} />

				<polygon points="0 15, 14 39, 0 63"
					className={"classsurface"+(sisi==="KANAN" ? vSimbolcolormesial : vSimbolcolordistal)}
					onClick={() => hdlKlikGigi(number,(sisi==="KANAN" ? "MESIAL" : "DISTAL"))} />
			</svg>
			)//->

			if(posisi === "BAWAH" && jenis === "GIGI_GERAHAM") return (
			<svg width="48" height="78" viewBox="0 0 48 78" className="classtooth-svg">
				<text x="23" y="74" textAnchor="middle" fontSize="13">{number}</text>
				
				{vSimbolteks !== "" && (
				<text x="23" y="12" textAnchor="middle" fontSize="15" font-weight="bold">{vSimbolteks}</text>
				)}
					
				<polygon points="3 15, 45 15, 45 63, 3 63" alt="ALL" className={"classall"+vSimbolcolorall}/>

				{vSimbolimageArr.map((vItems,vKeys) => (<React.Fragment key={vKeys}>{vItems}</React.Fragment>))}

				<polygon points="0 15, 48 15, 34 29, 14 29"
					className={"classsurface"+vSimbolcolorlingual}
					onClick={() => hdlKlikGigi(number,'LINGUAL')} />

				<polygon points="48 15, 48 63, 34 49, 34 29"
					className={"classsurface"+(sisi!=="KANAN" ? vSimbolcolormesial : vSimbolcolordistal)}
					onClick={() => hdlKlikGigi(number,(sisi!=="KANAN" ? "MESIAL" : "DISTAL"))} />

				<polygon points="14 49, 34 49, 48 63, 0 63"
					className={"classsurface"+vSimbolcolorvestibular}
					onClick={() => hdlKlikGigi(number,'VESTIBULAR')} />

				<polygon points="0 15, 14 29, 14 49, 0 63"
					className={"classsurface"+(sisi==="KANAN" ? vSimbolcolormesial : vSimbolcolordistal)}
					onClick={() => hdlKlikGigi(number,(sisi==="KANAN" ? "MESIAL" : "DISTAL"))} />

				<polygon points="14 29, 34 29, 34 49, 14 49"
					className={"classsurface"+vSimbolcoloroccusal}
					onClick={() => hdlKlikGigi(number,`OCCUSAL`)}/>
			</svg>
			)//>

			return (
			<svg width="48" height="78" viewBox="0 0 48 78" className="classtooth-svg">
				<text x="23" y="13" textAnchor="middle" fontSize="13">{number}</text>
				
				{vSimbolteks !== "" && (
				<text x="23" y="74" textAnchor="middle" fontSize="15" font-weight="bold">{vSimbolteks}</text>
				)}

				<polygon points="3 15, 45 15, 45 63, 3 63" alt="ALL" className={"classall"+vSimbolcolorall}/>

				{vSimbolimageArr.map((vItems,vKeys) => (<React.Fragment key={vKeys}>{vItems}</React.Fragment>))}

				<polygon points="0 15, 48 15, 34 29, 14 29"
					className={"classsurface"+vSimbolcolorvestibular}
					onClick={() => hdlKlikGigi(number,'VESTIBULAR')} />

				<polygon points="48 15, 48 63, 34 49, 34 29"
					className={"classsurface"+(sisi==="KANAN" ? vSimbolcolormesial : vSimbolcolordistal)}
					onClick={() => hdlKlikGigi(number,(sisi==="KANAN" ? "MESIAL" : "DISTAL"))} />

				<polygon points="14 49, 34 49, 48 63, 0 63"
					className={"classsurface"+vSimbolcolorlingual}
					onClick={() => hdlKlikGigi(number,'LINGUAL')} />

				<polygon points="0 15, 14 29, 14 49, 0 63"
					className={"classsurface"+(sisi!=="KANAN" ? vSimbolcolormesial : vSimbolcolordistal)}
					onClick={() => hdlKlikGigi(number,(sisi!=="KANAN" ? "MESIAL" : "DISTAL"))} />

				<polygon points="14 29, 34 29, 34 49, 14 49"
					className={"classsurface"+vSimbolcoloroccusal}
					onClick={() => hdlKlikGigi(number,'OCCUSAL')} />
			</svg>
			)//->
		});
	},[uDatagigiArr.length,
	uDatahistoriArr.length,
	uDatainputArr.length,
	uISDatainputupdate]);

	const ContentMain = () => {
		if(uHtml500msg) return (
		<div className="p-lg-5">{UFunc.renderHTML(uHtml500msg)}</div>
		)//->

		if(uISCardloading) return (
			<div align="center" className="py-3 py-md-5"><CSpinner color="success"/></div>
		)//->

		const vGigiataspermanenArr = uDatagigiArr.
			filter(vItems=>vItems.posisi==="ATAS" && vItems.tipe==="PERMANEN").
			sort((a, b) => a.kuadran - b.kuadran);
		const vGigiatassusuArr = uDatagigiArr.
			filter(vItems=>vItems.posisi==="ATAS" && vItems.tipe!=="PERMANEN").
			sort((a, b) => a.kuadran - b.kuadran);

		const vGigibawahpermanenArr = uDatagigiArr.
			filter(vItems=>vItems.posisi==="BAWAH" && vItems.tipe==="PERMANEN").
			sort((a, b) => a.kuadran - b.kuadran);
		const vGigibawahsusuArr = uDatagigiArr.
			filter(vItems=>vItems.posisi==="BAWAH" && vItems.tipe!=="PERMANEN").
			sort((a, b) => a.kuadran - b.kuadran);

		return (
		<>
		<div className="d-none">{JSON.stringify(uDatakondisiArr)}</div>

		<div className="d-md-none">
			<p>Maaf,</p>
			<p className="fw-bold">Fitur Ini Tidak Tersedia untuk Smartphone..</p>
			<p>Mohon Gunakan PC/Laptop/Tablet, Atau Putar Layar Anda dalam Mode Vertikal..</p>
			<p className="fw-bold">Terima Kasih,</p>
		</div>

		<div className="d-none d-md-block d-lg-none">
			<div className="classmodontogram">
			{vGigiataspermanenArr.map((vItems,vKeys)=>{
				return (
					<ContentToothM key={vKeys} 
						number={vItems.nomor_gigi}
						posisi={vItems.posisi}
						sisi={vItems.sisi}
						jenis={vItems.jenis}/>
				)//>
			})}
			</div>

			<div className="classmodontogram">
			{vGigiatassusuArr.map((vItems,vKeys)=>{
				return (
					<ContentToothM key={vKeys} 
						number={vItems.nomor_gigi}
						posisi={vItems.posisi}
						sisi={vItems.sisi}
						jenis={vItems.jenis}/>
				)
			})}
			</div>

			<div className="classmodontogram">
			{vGigibawahsusuArr.map((vItems,vKeys)=>{
				return (
					<ContentToothM key={vKeys} 
						number={vItems.nomor_gigi}
						posisi={vItems.posisi}
						sisi={vItems.sisi}
						jenis={vItems.jenis}/>
				)
			})}
			</div>

			<div className="classmodontogram">
			{vGigibawahpermanenArr.map((vItems,vKeys)=>{
				return (
					<ContentToothM key={vKeys} 
						number={vItems.nomor_gigi}
						posisi={vItems.posisi}
						sisi={vItems.sisi}
						jenis={vItems.jenis}/>
				)
			})}
			</div>
		</div>

		<div className="d-none d-lg-block">
			<div className="classodontogram">
			{vGigiataspermanenArr.map((vItems,vKeys)=>{
				return (
				<>
					<ContentTooth key={vKeys} 
						number={vItems.nomor_gigi}
						posisi={vItems.posisi}
						sisi={vItems.sisi}
						jenis={vItems.jenis}/>
					{vKeys===7 && (<span className="mx-1"/>)}
				</>
				)//>
			})}
			</div>

			<div className="classodontogram">
			{vGigiatassusuArr.map((vItems,vKeys)=>{
				return (
				<>
					<ContentTooth key={vKeys} 
						number={vItems.nomor_gigi}
						posisi={vItems.posisi}
						sisi={vItems.sisi}
						jenis={vItems.jenis}/>
					{vKeys===4 && (<span className="mx-1"/>)}
				</>
				)//>
			})}
			</div>

			<div className="classodontogram">
			{vGigibawahsusuArr.map((vItems,vKeys)=>{
				return (
				<>
					<ContentTooth key={vKeys} 
						number={vItems.nomor_gigi}
						posisi={vItems.posisi}
						sisi={vItems.sisi}
						jenis={vItems.jenis}/>
					{vKeys===4 && (<span className="mx-1"/>)}
				</>
				)//>
			})}
			</div>

			<div className="classodontogram">
			{vGigibawahpermanenArr.map((vItems,vKeys)=>{
				return (
				<>
					<ContentTooth key={vKeys} 
						number={vItems.nomor_gigi}
						posisi={vItems.posisi}
						sisi={vItems.sisi}
						jenis={vItems.jenis}/>
					{vKeys===7 && (<span className="mx-1"/>)}
				</>
				)//>
			})}
			</div>
		</div>
		</>
		)//->
	}
	const ContentDatakondisi = () => {
		if(uHtml500msg || uISCardloading) return "";
		if(uDatainputArr.length <= 0) return "";

		let vNumber = 0;
		return (
		<>
		<div className="my-3 d-none d-md-block"  style={{borderBottom:"1px dotted gray"}}/>

		<div className="mx-auto d-none d-md-block" style={{minWidth:450,width:(window.innerWidth > 768 ? "70%" : "85%")}}>
		<div className="my-3 pb-2 text-center fw-bolder classborderbottom">
			&middot; ODONTOGRAM UPDATE &middot;
		</div>

		{uDatainputArr.map((vItems,vKeys)=>{
			const {
				nomor_gigi,kondisi_id,kondisi_caption,surface,catatan,
				status_hapus
			} = vItems

			if((status_hapus||"TIDAK") !== "YA") {
				vNumber++;

				return (
				<CRow className="mx-0 my-1 pb-1 classfontsmaller classborderbottom" key={vKeys}>
					<CCol xs="1" className="px-1 text-end">{vNumber}.</CCol>
					<CCol xs="1" className="px-0 text-start text-primary">
						No-{nomor_gigi}
					</CCol>
					<CCol xs="3" className="px-1 text-start ">
						<b>{kondisi_caption}</b>
					</CCol>
					<CCol xs="2" className="px-1 text-center">
						{surface}
					</CCol>
					<CCol xs="4" className="px-1 text-start text-capitalize ">
						<i>{catatan||"-"}</i>
					</CCol>
					<CCol xs="1" className="px-0 text-center ">
						<CLink onClick={()=>hdlKlikKondisiedit(vKeys)} className="mx-0 classcursorpointer classikontabel classikonedit"/>
						<CLink onClick={()=>hdlKlikKondisihapus(vKeys)} className="mx-0 classcursorpointer classikontabel classikonhapus"/>
					</CCol>
				</CRow>
				)//->
			}

			return "";
		})}
		</div>
		</>
		)//->
	}
	const ContentDlgform = () => {
		if(!uISDlgformshow) return (<></>)//->

		const vDatagigiObj	= uDlgformdataObj.data_obj || {};

		return (
		<CForm className="mx-lg-5 px-lg-5">
		<CRow className="my-1">
			<CCol>Nama Gigi</CCol>
			<CCol xs="8" className="text-end text-lg-start">
				<b className="text-uppercase text-primary">
				{vDatagigiObj.nama_gigi} &middot; {vDatagigiObj.tipe} &middot; ({vDatagigiObj.nomor_gigi})
				</b>
			</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-1">
			<CCol>Kondisi Gigi</CCol>
			<CCol xs="8" lg="6" className="text-end text-lg-start">
				<CInputGroup size="sm">
				<CFormInput size="sm" readOnly={true}
					value={uDlgformdataObj.input_kondisicaption||""}
					onClick={()=>hdlKlikkondisicaption()}
					placeholder="--Pilih Kondisi Gigi.."
					id="inpkondisicaption"/>
				<CButton size="sm" color="dark" 
					onClick={()=>hdlKlikkondisicaption()}
					id="btnkondisilookup">
				<CIcon icon="cilSearch"/>
				</CButton>
				</CInputGroup>
			</CCol>
			<CCol lg="2" className="d-none d-lg-block"/>
		</CRow>

		<CRow className="my-1">
			<CCol>Permukaan Gigi</CCol>
			<CCol xs="8" lg="6" className="text-end text-lg-start">
				<CFormSelect size="sm"
					value={uDlgformdataObj.input_surface||"ALL"}
					onChange={(e)=>{uDlgformdataObj.input_surface=e.target.value;setEfekview(!uEfekview)}}
					id="inpsurface">
				<option value="ALL">SELURUH PERMUKAAN</option>
				<option value="MESIAL">MESIAL</option>
				<option value="OCCUSAL">OCCUSAL</option>
				<option value="DISTAL">DISTAL</option>
				<option value="VESTIBULAR">VESTIBULAR</option>
				<option value="LINGUAL">LINGUAL</option>
				</CFormSelect>
			</CCol>
			<CCol lg="2" className="d-none d-lg-block"/>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-1">
			<CCol>Catatan</CCol>
			<CCol xs="8" className="text-end text-lg-start">
				<CFormInput size="sm" maxLength={100}
					value={uDlgformdataObj.input_catatan||""}
					onChange={(e)=>{uDlgformdataObj.input_catatan=e.target.value;setEfekview(!uEfekview)}}
					onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
					id="inpcatatan"/>
			</CCol>
		</CRow>
		</CForm>
		)//->
	}
	const contentDlgview = () => {
		if(!uISDlgviewshow) return (<></>)//>

		return (
		<div className="mx-lg-5 px-lg-5">
		{uDatakondisiArr.map((vItems,vKeys)=>{
			let vSimbolteks  = "";
			let vSimbolcolorvestibular  = "";
			let vSimbolcolormesial  = "";
			let vSimbolcolorlingual  = "";
			let vSimbolcolordistal  = "";
			let vSimbolcoloroccusal  = "";
			let vSimbolcolorall  = "";
			let vSimbolimageArr = [];

			//--TEKS--/
			if(vItems.jenis_simbol==="TEKS") {
				vSimbolteks 	= vItems.kode;
			}
			//--END TEKS--/

			//--COLOR--/
			if(vItems.jenis_simbol==="COLOR") {
				if(vItems.surface === "PARTIAL")
					vSimbolcoloroccusal	+= " class"+(vItems.kode||"").toLowerCase();
				else
					vSimbolcolorall		+= " class"+(vItems.kode||"").toLowerCase();
			}
			//--END COLOR--/

			//--IMAGE--/
			if(vItems.jenis_simbol==="IMAGE") {
				let vPoints;
				if((vItems.kode||"").toLowerCase()==="abu") {
					vPoints		= "0 16,36 16,36 20, 0 20";
					vSimbolimageArr.push(
					<polygon points={vPoints} alt="IMAGE" stroke-width="3" stroke="black" fill="gray"/>
					)//>
				}
				if((vItems.kode||"").toLowerCase()==="cfr") {
					vPoints		= "36";
					vSimbolimageArr.push(
						<text x="18" y={vPoints} 
							textAnchor="middle" fontSize="45" font-weight="bold">#</text>
					)//>
				}
				if((vItems.kode||"").toLowerCase()==="mam") {
					vPoints		= "45";
					vSimbolimageArr.push(
					<text x="18" y={vPoints} textAnchor="middle" fontSize="90">&times;</text>
					)//>
				}
				if((vItems.kode||"").toLowerCase()==="meb") {
					vPoints		= "0 16,36 16,36 20, 0 20";
					vSimbolimageArr.push(
					<polygon points={vPoints} alt="IMAGE" stroke-width="3" stroke="black" fill="red"/>
					)//>
				}
				if((vItems.kode||"").toLowerCase()==="mis") {
					vPoints		= "45";
					vSimbolimageArr.push(
					<text x="18" y={vPoints} textAnchor="middle" fontSize="90">&times;</text>
					)//>
				}
				if((vItems.kode||"").toLowerCase()==="mpm") {
					vSimbolimageArr.push(
					<circle cx="18" cy="18" r="15" fill="none" stroke="black" stroke-width="3"/>
					)//>
				}
				if((vItems.kode||"").toLowerCase()==="nvt") {
					vPoints	= "12 36, 24 36, 18 49";
					vSimbolimageArr.push(
					<polygon points={vPoints} alt="IMAGE" stroke-width="2" fill="none" stroke="black"/> 
					)//>
				}
				if((vItems.kode||"").toLowerCase()==="pob") {
					vPoints		= "0 16,36 16,36 20, 0 20";
					vSimbolimageArr.push(
					<polygon points={vPoints} alt="IMAGE" stroke-width="3" stroke="black" fill="green"/>
					)//>
				}
				if((vItems.kode||"").toLowerCase()==="pon") {
					vPoints		= "45";
					vSimbolimageArr.push(
					<text x="18" y={vPoints} textAnchor="middle" fontSize="90">&times;</text>
					)//>
					vSimbolimageArr.push(
					<polygon points="2 2, 34 2, 34 34, 2 34" stroke="black" stroke-width="3" fill="none"/>
					)//>
				}
				if((vItems.kode||"").toLowerCase()==="rct") {
					vPoints	= "12 36, 24 36, 18 49";
					vSimbolimageArr.push(
					<polygon points={vPoints} alt="IMAGE" stroke-width="1" fill="black" stroke="black"/> 
					)//>
				}
				if((vItems.kode||"").toLowerCase()==="rrx") {
					vPoints		= "36";
					vSimbolimageArr.push(
					<text x="18" y={vPoints} textAnchor="middle" fontSize="50">V</text>
					)//>
				}
			}
			//--END IMAGE--/

			return (
			<>
			<CRow className="my-1 pb-1 classborderbottom" key={vKeys}>
				<CCol xs="2" className="text-start">
				<svg width="36" height="50" viewBox="0 0 36 50" className="classtooth-svg">
					{vSimbolteks !== "" && (
					<text x="18" y="45" textAnchor="middle" fontSize="13" font-weight="bold">{vSimbolteks}</text>
					)}

					<polygon points="2 2, 34 2, 34 34, 2 34"
						className={"classall"+vSimbolcolorall}/>

					{vSimbolimageArr.map(vItemsimbol => vItemsimbol)}

					<polygon points="0 0, 36 0, 24 12, 12 12"
						className="classtoothsample"/>

					<polygon points="24 12, 36 0, 36 36, 24 24"
						className="classtoothsample"/>

					<polygon points="12 24,24 24, 36 36,0 36"
						className="classtoothsample"/>

					<polygon points="0 0, 12 12,12 24,0 36"
						className="classtoothsample"/>

					<polygon points="12 12, 24 12, 24 24,12 24"
						className={"classtoothsample"+vSimbolcoloroccusal}/>
				</svg>
				</CCol>

				<CCol xs="10">
					<b>{vItems.kode}</b>
					<span className="mx-1">&middot;</span>
					<span>{vItems.arti}</span>

					{(vItems.keterangan||"")!=="" && (
					<div className="classfontsmaller fst-italic">
						<small>{vItems.keterangan}</small>
					</div>
					)}
					<div className="d-none">{vSimbolimageArr.length}</div>
				</CCol>
			</CRow>
			</>
			)
		})}
		</div>
		)//->
	}

	const apiLoadawal = () => {
		setHtml500msg();

		setDatagigiArr([]);
		setDatakondisiArr([]);
		setDataodontogramArr([]);
		setCardloading(true);

		/*//--TESTING_FRONTEND--/
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_pasienid : pasienID,
			send_rekammedisid : rekammedisID,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_periksa/ld_odontogram";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); }})
		.then((output_string) => {
			const vTimeEnd = new Date(); const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
			setCardloading(false);
			setLoading(false);
			if(output_string.tampil) {
				//console.log("(MyCardodontogram - apiLoadawal) datagigi : "+output_string.datagigi);
				setDatagigiArr(JSON.parse(output_string.datagigi||"[]"));
				setDatakondisiArr(JSON.parse(output_string.datakondisi||"[]"));
				setDataodontogramArr(JSON.parse(output_string.dataodontogram||"[]"));
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(MyCardodontogram - apiLoadawal) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired(); return;
			}

			if(vTimeDiff > 500) setSessionaktif(true);
			else {
				let vTimeout = setTimeout(()=>{ clearTimeout(vTimeout); setSessionaktif(true);},500)
			}
		})
		.catch((error) =>{
			setCardloading(false);
			setLoading(false);
			console.log("(MyCardodontogram - apiLoadawal) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});

		apiLoadawal();

		return () => {
			setDatagigiArr([]); setDatakondisiArr([]); setDataodontogramArr([]);
			setDatainputArr([]); setDatahistoriArr([]);
			setDlgformshow(false); setDlglookupshow(false)
		}
	},[]);
	React.useEffect(()=>{
		if((parseInt(rekammedisID)||0) <= 0) {
			const vKondisihistoriArr = uDataodontogramArr.
				filter(vItems=>(parseInt(vItems.id_rekammedis)||0)!==(parseInt(rekammedisID)||0)).
				map(vItems=>{
				return {
					id: vItems.id,
					nomor_gigi	: vItems.nomor_gigi,
					kondisi_id	: vItems.kondisi_id,
					kondisi_caption	: vItems.kondisi_caption,
					kondisi_kode	: vItems.kondisi_kode,
					kondisi_simbol	: vItems.kondisi_simbol,
					surface	: vItems.kondisi_surface,
					catatan: vItems.catatan,
				}
			});
			setDatahistoriArr(vKondisihistoriArr);
		}

		const vKondisiinputArr = uDataodontogramArr.
			filter(vItems=>(parseInt(vItems.id_rekammedis)||0)===(parseInt(rekammedisID)||0)).
			map(vItems=>{
			return {
				id: vItems.id,
				nomor_gigi	: vItems.nomor_gigi,
				kondisi_id	: vItems.kondisi_id,
				kondisi_caption	: vItems.kondisi_caption,
				kondisi_kode	: vItems.kondisi_kode,
				kondisi_simbol	: vItems.kondisi_simbol,
				surface	: vItems.kondisi_surface,
				catatan: vItems.catatan,
			}
		});

		if(vKondisiinputArr.length > 0) {
			setDatainputArr(vKondisiinputArr);
			setDatainputupdate(true);
		}
	},[uDataodontogramArr,uDataodontogramArr.length]);
	React.useEffect(()=>{
		if(uOdontograminputArr.length <= 0) {
			setDatainputArr([]);
		}
	},[uOdontograminputArr.length]);
	React.useEffect(()=>{
		if(!uISDlgformshow) 
			{setDlgformdataObj({}); return}

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);

			if(!isMobile)
				document.getElementById("inpkondisicaption") && 
					document.getElementById("inpkondisicaption").focus()
		},350)
	},[uISDlgformshow]);
	React.useEffect(()=>{
		if(!uISDlglookupshow) 
			{setDlglookupObj({}); return}
	},[uISDlglookupshow]);
	React.useEffect(()=>{
		if(!uISDlgviewshow){ setDlgviewdataObj({}); return }
	},[uISDlgviewshow]);
	React.useEffect(()=>{
		if(!uISDatainputupdate) return;

		setDatainputupdate(false);

		//--PROSES_DISINI--/
		uDispatch({type: "set", gOdontograminputArr: uDatainputArr});
		uDispatch({type: "set", effectApp: !uEfekapp});
		//--END PROSES_DISINI--/
	},[uISDatainputupdate]);
	React.useEffect(()=>{
		if(!uISTrigerreload) return;

		apiLoadawal();
	},[uISTrigerreload]);

	//console.log("(MyCardodontogram) uDataodontogramArr = "+JSON.stringify(uDataodontogramArr));

	return (
	<>
		<CCard className="classbgcard mb-3">
		<CCardHeader className="py-1 classfontsmaller d-flex justify-content-between">
		Odontogram
		
		<CTooltip className="d-none d-lg-block" content="--Keterangan Odontogram">
			<CLink onClick={hdlKlikInfoodontogram} className="classcursorpointer">
			<CIcon icon="cilInfo" className="align-middle classikonbox" height={23}/>
			</CLink>
		</CTooltip>
		</CCardHeader>

		<CCardBody className="px-md-0">
			{ContentMain()}{ContentDatakondisi()}
		</CCardBody>
		</CCard>

		<MyDialoglookup
			show={uISDlglookupshow}
			toggle={()=>setDlglookupshow(false)}
			dialogHeader={uDlglookupObj.dlg_header||"Lookup Data"}
			inputValue={uDlglookupObj.dlg_input || ""}
			dataItemArr={uDlglookupObj.data_arr || []} 
			onKlikPilihItem={hdlKlikDlglookuppilih}
			id="iddialoglookup"
			{...props}/>

		<MyDialogform
			options={{centered:true,size:"lg"}}
			onSimpan={()=>hdlKlikDlgformsimpan()}
			show={uISDlgformshow} 
			dialogHeader={uDlgformdataObj.dlg_header||""}
			toggle={()=>setDlgformshow(false)}
			renderContent={ContentDlgform()}/>

		<MyDialogview
			options={{centered:true,size:"lg"}}
			show={uISDlgviewshow} 
			dialogHeader={uDlgviewdataObj.dlg_header||""}
			toggle={()=>setDlgviewshow(false)}
			renderContent={contentDlgview()}/>
	</>
	)//->
}

export default MyCardodontogram;